import logo from './logo.svg';
import React, { StrictMode } from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';

import DStopbar from './DirectSeller/DStopbar';
import DSleftmenu from './DirectSeller/DSleftmenu';

import StoreTopBar from './Store/StoreTopBar';
import StoreLeftmenu from './Store/Storeleftmenu';

import Depottopbar from './Depot/Depottopbar';
import Depotleftmenu from './Depot/Depotleftmenu';

import Branchtopbar from './Branch/Branchtopbar';
import Branchleftmenu from './Branch/Branchleftmenu';

import Login from './login';

import Dsroutes from './Dsroutes'
import Depotroutes from './Depotroutes';
import Storeroutes from './Storeroutes';
import Branchroutes from './Branchroutes';
import NotFound from './NotFound.js';
import AdminTopBar from './Admin/Admintopbar';
import AdminLeftmenu from './Admin/Adminleftmenu';
import Adminroutes from './Adminroutes';


import { Saleinvoicepanel } from './Reports/saleinvoicepanel.js';
import Invoice from './invoicebill.js';
//import Adminlogin from './Admin/Adminlogin.js';
import StoreRegistrationForm from './Registration/Storeregistrationform.js';
import DepotRegistrationForm from './Registration/DepotRegistrationForm.js';
import BranchRegistrationForm from './Registration/BranchRegistrationForm.js';

import RegistrationForm from './Registration/RegistrationDsform.js';
import AdminLogin from './adminlogin.js';


function App() {
  const path = useLocation().pathname;
  const [isregistration,setisregistration] = useState(false);

  console.log("FINANCIALY YEAR=", process.env.REACT_APP_FINANCIAL_YEAR);
  console.log("token=", localStorage.getItem("token"));
  useEffect(() => {
    console.log(localStorage.getItem("islogin"), "login");
   
    const pathstatus=path === "/Registrationform" || path === "/StoreRegistrationForm" || path === "/DepotRegistrationForm" || path === "/BranchRegistrationForm" || path === "AdminLogin";
    setisregistration(pathstatus);

  }, [])

  return (
<>
    <Routes>
    {/* <Route path="/invoicebill" element={<Invoice />} /> */}
    <Route path="/Registrationform" element={<RegistrationForm />} />
  <Route path="/StoreRegistrationForm" element={<StoreRegistrationForm />} />
  <Route path="/DepotRegistrationForm" element={<DepotRegistrationForm />} />
  <Route path="/BranchRegistrationForm" element={<BranchRegistrationForm />} />
  <Route path="/AdminLogin" element={<AdminLogin />} />
  {/* <Route path="/adminlogin" element={<Adminlogin />} /> */}
  {/* <Route path="/" element={<Login />} /> */}
</Routes>
      
         
     
      
      
     
        <div>
          {/* {console.log("status", (localStorage.getItem("adminStatus")))}
          {console.log("applicantType", (localStorage.getItem("applicantType")))}
          {console.log("panelstatus", (localStorage.getItem("panelstatus")))}
          <Login /> */}
          {console.log("isregistration=",isregistration)}
          { isregistration==false &&(<div>
            {localStorage.getItem("applicantType") == 'Admin' ? (<><AdminTopBar /><AdminLeftmenu /><Adminroutes /></>):
            (localStorage.getItem("applicantType") == "DS" ) ? (<><DStopbar /><DSleftmenu /> <Dsroutes /></>) :

              (localStorage.getItem("applicantType") == "Store" ) ? (<><StoreTopBar /><StoreLeftmenu /><Storeroutes /></>) :
                (localStorage.getItem("applicantType") == "Depot" ) ? (<><Depottopbar /><Depotleftmenu />  <Depotroutes /></>) :
                  (localStorage.getItem("applicantType") == "Branch" ) ? (<><Branchtopbar /><Branchleftmenu /> <Branchroutes /></>) :
                  <Login />}</div>)
            
          }

        </div>




        </>

  )

}

export default App;
